var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-card-title',[(!_vm.isAdmin)?_c('span',[_vm._v("Twoje zgłoszenia")]):_vm._e(),(_vm.isAdmin)?_c('span',[_vm._v("Wszystkie reklamacje")]):_vm._e()]),_c('v-card-title',[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-btn-toggle',{attrs:{"mandatory":"","rounded":""},model:{value:(_vm.toggle_one),callback:function ($$v) {_vm.toggle_one=$$v},expression:"toggle_one"}},[_c('v-btn',{attrs:{"value":false}},[_vm._v(" Niezrealizowane ")]),_c('v-btn',{attrs:{"value":true}},[_vm._v(" Zrealizowane ")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Szukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.toggle_one),expression:"!toggle_one"}],staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.uncompletedTickets,"loading":_vm.loading,"loading-text":"Ładowanie... Proszę czekać","sort-by":['created', 'status'],"sort-desc":[false, true],"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.isAdmin)?_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""},on:{"click":function($event){_vm.editedTicket = item;
              _vm.overlay = !_vm.overlay;}}},[_vm._v(_vm._s(_vm.getStatusName(item.status)))]):_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(_vm.getStatusName(item.status)))])]}},{key:"item.transport_id",fn:function(ref){
              var item = ref.item;
return [_c('a',{attrs:{"href":'/transport/' + item.transport_id,"target":"_blank"}},[_vm._v(" Zamówienie "+_vm._s(item.transport_id)+" ")])]}},{key:"item.details",fn:function(ref){
              var item = ref.item;
return [_c('a',{attrs:{"href":'/zgloszenia/' + item.details}},[_vm._v(" Sprawdź szczegóły ")])]}}],null,true)}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle_one),expression:"toggle_one"}],staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.completedTickets,"loading":_vm.loading,"loading-text":"Ładowanie... Proszę czekać","sort-by":['created', 'status'],"sort-desc":[false, true],"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(_vm.isAdmin)?_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""},on:{"click":function($event){_vm.editedTicket = item;
              _vm.overlay = !_vm.overlay;}}},[_vm._v(_vm._s(_vm.getStatusName(item.status)))]):_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(_vm.getStatusName(item.status)))])]}},{key:"item.transport_id",fn:function(ref){
              var item = ref.item;
return [_c('a',{attrs:{"href":'/transport/' + item.transport_id,"target":"_blank"}},[_vm._v(" Zamówienie "+_vm._s(item.transport_id)+" ")])]}},{key:"item.details",fn:function(ref){
              var item = ref.item;
return [_c('a',{attrs:{"href":'/zgloszenia/' + item.details}},[_vm._v(" Sprawdź szczegóły ")])]}}],null,true)})],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"dark":false,"light":true}},[_c('v-toolbar',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.overlay = !_vm.overlay}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('TicketStatus',{attrs:{"ticket":_vm.editedTicket.id}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }