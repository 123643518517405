<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          <span v-if="!isAdmin">Twoje zgłoszenia</span>
          <span v-if="isAdmin">Wszystkie reklamacje</span>
        </v-card-title>
        <v-card-title>
          <v-col cols="12" sm="3" md="3">
            <v-btn-toggle v-model="toggle_one" mandatory rounded>
              <v-btn :value="false"> Niezrealizowane </v-btn>
              <v-btn :value="true"> Zrealizowane </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="search" label="Szukaj" single-line hide-details></v-text-field>
          </v-col>
        </v-card-title>
        <v-data-table
          :headers="tableHeaders"
          :items="uncompletedTickets"
          :loading="loading"
          loading-text="Ładowanie... Proszę czekać"
          :sort-by="['created', 'status']"
          :sort-desc="[false, true]"
          class="elevation-1"
          :search="search"
          v-show="!toggle_one"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="isAdmin"
              @click="
                editedTicket = item;
                overlay = !overlay;
              "
              :color="getStatusColor(item.status)"
              dark
              >{{ getStatusName(item.status) }}</v-chip
            >
            <v-chip v-else :color="getStatusColor(item.status)" dark>{{
              getStatusName(item.status)
            }}</v-chip>
          </template>

          <template v-slot:[`item.transport_id`]="{ item }">
            <a :href="'/transport/' + item.transport_id" target="_blank">
              Zamówienie {{ item.transport_id }}
            </a>
          </template>

          <template v-slot:[`item.details`]="{ item }">
            <a :href="'/zgloszenia/' + item.details"> Sprawdź szczegóły </a>
          </template>
        </v-data-table>

        <v-data-table
          :headers="tableHeaders"
          :items="completedTickets"
          :loading="loading"
          loading-text="Ładowanie... Proszę czekać"
          :sort-by="['created', 'status']"
          :sort-desc="[false, true]"
          class="elevation-1"
          :search="search"
          v-show="toggle_one"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="isAdmin"
              @click="
                editedTicket = item;
                overlay = !overlay;
              "
              :color="getStatusColor(item.status)"
              dark
              >{{ getStatusName(item.status) }}</v-chip
            >
            <v-chip v-else :color="getStatusColor(item.status)" dark>{{
              getStatusName(item.status)
            }}</v-chip>
          </template>

          <template v-slot:[`item.transport_id`]="{ item }">
            <a :href="'/transport/' + item.transport_id" target="_blank">
              Zamówienie {{ item.transport_id }}
            </a>
          </template>

          <template v-slot:[`item.details`]="{ item }">
            <a :href="'/zgloszenia/' + item.details"> Sprawdź szczegóły </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- status dialog -->
    <v-overlay :value="overlay" :dark="false" :light="true">
      <v-toolbar>
        <v-spacer />
        <v-btn @click="overlay = !overlay" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <TicketStatus :ticket="editedTicket.id"></TicketStatus>
    </v-overlay>
  </v-container>
</template>
<script>
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import store from '../../store';
  import axios from 'axios';

  import TicketStatus from '../../components/TicketStatus.vue';

  export default {
    props: {
      source: String,
    },
    components: {
      TicketStatus,
    },
    data: () => ({
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      tableHeaders: [
        {
          text: 'LP',
          align: 'left',
          value: 'id',
        },
        {
          text: 'Data aktualizacji',
          value: 'created',
        },
        {
          text: 'Powiązany transport',
          value: 'transport_id',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Szczegóły',
          value: 'details',
        },
      ],

      completedTickets: [],
      uncompletedTickets: [],
      search: null,
      isAdmin: store.getters.isAdmin,

      editedTicket: {},
      overlay: false,
      loading: true,
      toggle_one: false,
    }),
    methods: {
      getStatusColor: function (status) {
        let r;
        switch (status) {
          case 0:
            r = 'blue';
            break;
          case 1:
            r = 'orange';
            break;
          case 2:
            r = 'green';
            break;
          case 3:
            r = 'red';
            break;
        }
        return r;
      },
      getStatusName: function (status) {
        let r;
        switch (status) {
          case 0:
            r = 'Oczekuje na rozpatrzenie';
            break;
          case 1:
            r = 'W trakcie rozpatrzenia';
            break;
          case 2:
            r = 'Zaakceptowana';
            break;
          case 3:
            r = 'Odrzucona';
            break;
        }
        return r;
      },
    },
    created() {
      this.loading = true;
      axios({
        url: this.$store.state.url + '/api/ticket/get',
        data: {
          token: this.$store.state.token,
        },
        method: 'POST',
      })
        .then(resp => {
          for (let [i, t] of resp.data.tickets.entries()) {
            let d = new Date(Date.parse(t.updated));

            // STATUS: ticket status
            if (t.status >= 2) {
              this.completedTickets.push({
                id: i + 1,
                created: d.toLocaleString('pl-PL'),
                transport_id: t.transport_id,
                status: t.status,
                details: t.ticket_id,
              });
            } else {
              this.uncompletedTickets.push({
                id: i + 1,
                created: d.toLocaleString('pl-PL'),
                transport_id: t.transport_id,
                status: t.status,
                details: t.ticket_id,
              });
            }
          }
          this.loading = false;
        })
        .catch((this.loading = false));
    },
  };
</script>
