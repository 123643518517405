<template>
  <div>
    <v-progress-circular v-show="loading" indeterminate size="64"></v-progress-circular>
    <v-card v-show="!loading" class="mx-auto" max-width="800" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">status</div>
          <v-list-item-title class="headline mb-1">Zmień status zgłoszenia</v-list-item-title>
          <v-list-item-subtitle>Tutaj możesz zmienić status zgłoszenia</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row>
          <v-col class="d-flex" cols="12" sm="6">
            <v-radio-group v-model="getStatus">
              <v-radio
                v-for="item in status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="3">
            <v-btn
              @click="updateStatus"
              :disabled="getStatus === null"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Zmień status</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'TicketStatus',
    props: {
      ticket: Number,
    },
    data: () => ({
      loading: true,
      status: [
        {
          id: 0,
          name: 'Oczekuje na rozpatrzenie',
        },
        {
          id: 1,
          name: 'W trakcie rozpatrzenia',
        },
        {
          id: 2,
          name: 'Zaakceptowana',
        },
        {
          id: 3,
          name: 'Odrzucona',
        },
      ],
      getStatus: null,
    }),
    methods: {
      updateStatus: function () {
        axios({
          url: this.$store.state.url + '/api/ticket/update',
          data: {
            token: this.$store.state.token,
            ticket_id: this.ticket,
            params: {
              status: this.getStatus,
            },
          },
          method: 'POST',
        })
          .then(() => {
            window.location.reload();
          })
          .catch(() => {});
      },
    },
    created() {
      setTimeout(() => {
        this.loading = false;
      }, 200);
    },
  };
</script>
